import classNames from "classnames";
import { useEffect, useState } from "react";
import {
  LoadingIconBurstIcon,
  LoadingIconCircleIcon,
  LoadingIconDotsIcon,
  LoadingIconFlowerIcon,
  LoadingIconGlobeIcon,
  LoadingIconHalfIcon,
  LoadingIconNutIcon,
  LoadingIconOverlapIcon,
  LoadingIconQuadrantsIcon,
  LoadingIconSplitIcon,
  LoadingIconStarIcon,
  LoadingIconWheelIcon,
} from "./icons";

type IconColor = "primary" | "primary-light" | "primary-dark";

export type Props = {
  isLoading: boolean;
  isOverlay: boolean;
  iconColor?: IconColor;
};

type Icon = {
  icon: JSX.Element;
  id: number;
};

const iconList = (iconColor: IconColor): Icon[] => {
  const className = classNames("block m-auto w-24 h-12", {
    "text-primary-500": iconColor === "primary",
    "text-secondary-100": iconColor === "primary-light",
    "text-primary": iconColor === "primary-dark",
  });

  return [
    {
      icon: <LoadingIconBurstIcon className={className} />,
      id: 1,
    },
    {
      icon: <LoadingIconCircleIcon className={className} />,
      id: 2,
    },
    {
      icon: <LoadingIconDotsIcon className={className} />,
      id: 3,
    },
    {
      icon: <LoadingIconFlowerIcon className={className} />,
      id: 4,
    },
    {
      icon: <LoadingIconGlobeIcon className={className} />,
      id: 5,
    },
    {
      icon: <LoadingIconHalfIcon className={className} />,
      id: 6,
    },
    {
      icon: <LoadingIconNutIcon className={className} />,
      id: 7,
    },
    {
      icon: <LoadingIconOverlapIcon className={className} />,
      id: 8,
    },
    {
      icon: <LoadingIconQuadrantsIcon className={className} />,
      id: 9,
    },
    {
      icon: <LoadingIconSplitIcon className={className} />,
      id: 10,
    },
    {
      icon: <LoadingIconStarIcon className={className} />,
      id: 11,
    },
    {
      icon: <LoadingIconWheelIcon className={className} />,
      id: 12,
    },
  ];
};
const shuffle = <T,>(array: T[]) => {
  return array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
};

const LoadingSpinner = ({
  isLoading,
  isOverlay = false,
  iconColor = "primary",
}: Props) => {
  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(false);

  const [randomIconList, setRandomIconList] = useState<Icon[]>();

  useEffect(() => {
    setRandomIconList(shuffle(iconList(iconColor)));
  }, [iconColor]);

  useEffect(() => {
    let timerTwo: NodeJS.Timeout | undefined;

    if (randomIconList) {
      const timer = setTimeout(() => {
        setShow(true);

        timerTwo = setTimeout(() => {
          setIndex((prevIndex) =>
            prevIndex === randomIconList.length - 1 ? 0 : prevIndex + 1
          );
          setShow(false);
        }, 200);
      }, 1700);

      return () => {
        clearTimeout(timer);
        clearTimeout(timerTwo);
      };
    }

    return clearTimeout(timerTwo);
  }, [index, randomIconList]);

  const renderSpinner = () => {
    if (randomIconList) {
      return (
        <div
          key={randomIconList[index].id}
          className={classNames("", {
            "animated fadeInUp": !show,
            "animated fadeOutUp": show,
          })}
        >
          <div
            className={classNames(
              "",
              { "[&>svg]:text-primary-500": iconColor === "primary" },
              { "[&>svg]:text-secondary": iconColor === "primary-light" }
            )}
          >
            {randomIconList[index].icon}
          </div>
        </div>
      );
    }
    return <div />;
  };

  return (
    <div className="relative">
      {isLoading && isOverlay && (
        <div
          className={classNames(
            "flex text-center bg-white bg-opacity-75 h-full w-full top-0 left-0 fixed z-[999]"
          )}
        >
          <div className="m-auto">{renderSpinner()}</div>
        </div>
      )}
      {isLoading && !isOverlay && (
        <div className="flex justify-center py-2">
          <div className="m-auto">{renderSpinner()}</div>
        </div>
      )}
    </div>
  );
};

export default LoadingSpinner;
