import React, { ReactNode } from "react";
import {
  CloseButtonProps as ToastCloseButtonProps,
  toast,
  ToastOptions,
} from "react-toastify";
import Button from "./Button";

interface SnackbarOptions {
  message: ReactNode;
  closeButtonText?: string;
  onClose?: (e: React.MouseEvent<HTMLElement>) => void;
}
type Props = Pick<SnackbarOptions, "message">;
type CloseButtonProps = {
  children: ReactNode;
  onClose?: (e: React.MouseEvent<HTMLElement>) => void;
} & Pick<ToastCloseButtonProps, "closeToast">;

const CloseButton = ({ children, closeToast, onClose }: CloseButtonProps) => {
  return (
    <Button
      fill="link"
      buttonStyle="borderless"
      spacing="no-padding"
      size="small"
      className="text-white underline hover:no-underline !font-semibold text-sm pr-3 py-1 hover:!text-secondary-400"
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        if (onClose) onClose(e);
        closeToast(e);
      }}
    >
      {children}
    </Button>
  );
};

const Snackbar = ({ message }: Props) => {
  return (
    <div className="flex items-center text-white">
      <div className="caption-2 ml-3">{message}</div>
    </div>
  );
};

Snackbar.Pop = (
  { message, closeButtonText = "Close", onClose }: SnackbarOptions,
  options?: ToastOptions<Record<string, never>>
) => {
  const renderCloseButton = ({ closeToast }: ToastCloseButtonProps) => {
    return (
      <CloseButton closeToast={closeToast} onClose={onClose}>
        {closeButtonText}
      </CloseButton>
    );
  };

  return toast(<Snackbar message={message} />, {
    position: "bottom-center",
    closeButton: renderCloseButton,
    className: "snackbar-container",
    ...options,
  });
};

export default Snackbar;
